export default {
	MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-2",
    BUCKET: "kbjones108-uploads"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://eakx1g6she.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_Jv3q5OpTC",
    APP_CLIENT_ID: "361ncfr6ihrp2slbed8ienlm4o",
    IDENTITY_POOL_ID: "us-east-2:fdb7fb46-1bb5-409c-9197-9a46b6e37f94"
  }
};